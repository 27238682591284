<!--begin::Main-->

    <ng-container *ngIf="selfLayout !== 'blank'; else blankLayout">
        <!-- begin:: Header Mobile -->
        <app-header-mobile #ktHeaderMobile id="kt_header_mobile" class="header-mobile align-items-center" [ngClass]="headerMobileClasses"></app-header-mobile>
        <!-- end:: Header Mobile -->
        <div class="d-flex flex-column flex-root h-100">
            <!--begin::Page-->
            <div class="d-flex flex-row flex-column-fluid page">
                <!-- begin:: Aside Left -->
                <ng-container *ngIf="asideSelfDisplay">
                    <ng-container *ngIf="asideMenuStatic">
                        <!--begin::STATIC Aside-->
                        <app-aside #ktAside id="kt_aside" class="aside aside-left d-flex flex-column flex-row-auto" [ngClass]="asideCSSClasses"></app-aside>
                        <!--end::STATIC Aside-->
                    </ng-container>
                    <ng-container *ngIf="!asideMenuStatic">
                        <!--begin::DYNAMIC Aside-->
                        <app-aside-dynamic #ktAside id="kt_aside" class="aside aside-left d-flex flex-column flex-row-auto" [ngClass]="asideCSSClasses"></app-aside-dynamic>
                        <!--end::DYNAMIC Aside-->
                    </ng-container>
                </ng-container>
                <!-- end:: Aside Left -->
    
                <!--begin::Wrapper-->
                <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
    
                    <!-- <app-header #ktHeader id="kt_header" class="header" [ngClass]="headerCSSClasses"></app-header> -->
    
                    <div class="content d-flex flex-column flex-column-fluid" id="kt_content" [ngClass]="contentClasses">
                        <ng-container *ngIf="subheaderDisplay">
                            <app-subheader-wrapper></app-subheader-wrapper>
                        </ng-container>
                        <ng-container *ngIf="contentExtended">
                            <router-outlet></router-outlet>
                        </ng-container>
    
                        <ng-container *ngIf="!contentExtended">
                            <div class="d-flex flex-column-fluid">
                                <div [ngClass]="contentContainerClasses">
                                    <router-outlet></router-outlet>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!--end::Wrapper-->
            </div>
            <!-- end:: Body -->
            <!--end::Page-->
    
            <!--begin::Offcanvases-->
            <ng-container *ngIf="extrasSearchOffcanvasDisplay">
            </ng-container>
    
            <ng-container *ngIf="extrasNotificationsOffcanvasDisplay">
            </ng-container>
    
            <ng-container *ngIf="extrasQuickActionsOffcanvasDisplay">
            </ng-container>
    
            <ng-container *ngIf="extrasCartOffcanvasDisplay">
            </ng-container>
    
            <ng-container *ngIf="extrasQuickPanelDisplay">
            </ng-container>
    
            <!-- <app-toolbar></app-toolbar> -->
    
            <ng-container *ngIf="extrasScrollTopDisplay">
            </ng-container>
            <!--end::Offcanvases-->
        </div>
    </ng-container>
    
    <ng-template #blankLayout>
        <div class="d-flex flex-column flex-root">
            <router-outlet></router-outlet>
        </div>
    </ng-template>
    <!--end::Main-->
