import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutService } from '../../../../core';
import { SubheaderService } from '../_services/subheader.service';
import { BreadcrumbItemModel } from '../_models/breadcrumb-item.model';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { EventService } from 'src/app/_services/event.service';

@Component({
  selector: 'app-subheader1',
  templateUrl: './subheader1.component.html',
})
export class Subheader1Component implements OnInit {
  subheaderCSSClasses = '';
  subheaderContainerCSSClasses = '';
  subheaderMobileToggle = false;
  subheaderDisplayDesc = false;
  subheaderDisplayDaterangepicker = false;
  title$: Observable<string>;
  breadcrumbs$: Observable<BreadcrumbItemModel[]>;
  breadcrumbs: BreadcrumbItemModel[] = [];
  description$: Observable<string>;
  @Input() title: string;
  currentDate = new Date();
  userName : any;
  firstCharacter : any;
  isImageLoading: boolean = false;
  imageToShow: any;

  constructor(
    private layout: LayoutService,
    private subheader: SubheaderService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private router: Router,
    private _eventService : EventService,
    private domSanitizer: DomSanitizer
  ) {

    this.title$ = this.subheader.titleSubject.asObservable();
  }

  ngOnInit() {
  this.callProfile()
  this.getProfilePicture()
    this.title$ = this.subheader.titleSubject.asObservable();
    this.breadcrumbs$ = this.subheader.breadCrumbsSubject.asObservable();
    this.description$ = this.subheader.descriptionSubject.asObservable();
    this.subheaderCSSClasses = this.layout.getStringCSSClasses('subheader');
    this.subheaderContainerCSSClasses = this.layout.getStringCSSClasses(
      'subheader_container'
    );
    this.subheaderMobileToggle = this.layout.getProp('subheader.mobileToggle');
    this.subheaderDisplayDesc = this.layout.getProp('subheader.displayDesc');
    this.subheaderDisplayDaterangepicker = this.layout.getProp(
      'subheader.displayDaterangepicker'
    );
    this.breadcrumbs$.subscribe((res) => {
      this.breadcrumbs = res;
      this.cdr.detectChanges(); 
    });
  }

  getloginDetails(){
    this.userName = localStorage.getItem('LoginDetails')
    this.firstCharacter =  this.userName?.charAt(0);
  }

  callProfile () {
    this.http.get("https://graph.microsoft.com/v1.0/me").subscribe( resp  => {
      this.userName = resp['givenName'];
      this.firstCharacter =  this.userName?.charAt(0);
    })
  }

  getProfilePicture() {
    this.isImageLoading = true;
    this._eventService.getImage('https://graph.microsoft.com/v1.0/me/photo/$value').subscribe(
      (blob) => {
        this.isImageLoading = false;

        var urlCreator = window.URL || window.webkitURL;
        this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(blob)
        );
      },
      (error) => {
        this.isImageLoading = false;
        console.log(error);
      }
    );
  }
  
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        console.log(reader.result);
        const imgRes: any = reader.result;
        this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(imgRes);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
