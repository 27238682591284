import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './_layout/layout.component';
//import { AuthGuard } from '../modules/auth/_services/auth.guard'; 


const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'engineer',
        loadChildren: () =>
          import('../modules/engineer/engineer.module').then(
            (m) => m.EngineerModule
          ),
      },
      {
        path: 'on-boarding',
        loadChildren: () =>
        import('../modules/on-boarding/on-boarding.module').then(
            (m) => m.OnBoardingModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'error/404',
      },
    ], 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
