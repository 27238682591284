<!--begin::Brand-->
<div class="brand flex-column-auto" id="kt_brand" [ngClass]="brandClasses">
    <!--begin::Logo-->
    <a routerLink="/dashboard" class="brand-logo">
      
        <img src="./assets/media/logos/nde_logo.svg_.png" height="40px" class="w-100" alt="NDE Logo" />
    </a>
    
    <!--end::Logo-->

    <ng-container *ngIf="asideSelfMinimizeToggle">
        <button class="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
            <span [inlineSVG]="
          './assets/media/svg/icons/Navigation/Angle-double-left.svg'
        " cacheSVG="true" class="svg-icon svg-icon-xl"></span>
        </button>
    </ng-container>
</div>
<!--end::Brand-->

<!--begin::Aside Menu-->
<div class="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
    <ng-container *ngIf="disableAsideSelfDisplay">
        <!--begin::Header Logo-->
        <a routerLink="/dashboard">
            <!-- <img alt="Logo" [attr.src]="headerLogo" /> -->
            <img src="./assets/media/logos/nde_logo.svg.png" class="w-100" alt="NDE Logo" />
        </a>
        <!--end::Header Logo-->
    </ng-container>
    <!--begin::Menu Container-->
    <div id="kt_aside_menu" class="aside-menu" [ngClass]="asideMenuCSSClasses" data-menu-vertical="1" [attr.data-menu-scroll]="asideMenuScroll" [attr.data-menu-dropdown]="asideMenuDropdown" data-menu-dropdown-timeout="500">
        <!-- begin::Menu Nav -->
        <ul class="menu-nav" [ngClass]="ulCSSClasses">

            <!--Master Data MENU-->
            
            <li class="menu-item" aria-haspopup="true" routerLinkActive="menu-item-active">
                <a class="menu-link" routerLink="/dashboard" routerLinkActive="active">
                    <span class="svg-icon menu-icon"></span>
                    <!-- <span [inlineSVG]="'./assets/media/svg/icons/Communication/Add-user.svg'" cacheSVG="true"
                        class="svg-icon menu-icon"></span> -->
                    <span class="menu-text">Dashboard</span>
                    <!-- <span class="menu-text" translate="MENU.USER_PROFILE"></span> -->

                </a>
            </li>
            <li class="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover" routerLinkActive="menu-item-open">
                <a class="menu-link menu-toggle" routerLinkActive="active">

                    <span class="svg-icon menu-icon">
                        <i class="fas fa-file" aria-hidden="true"></i></span>
                    <span class="menu-text" translate="ON Boarding"></span>
                    <i class="menu-arrow"></i>
                </a>
                <div class="menu-submenu">
                    <i class="menu-arrow"></i>
                    <ul class="menu-subnav">
                        <li class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link" routerLink="/on-boarding/EngManager" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <span class="menu-text">Engineers And Managers</span>
                            </a>
                        </li>
                        <li class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link" routerLink="/on-boarding/Clients" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <span class="menu-text">Clients</span>
                            </a>
                        </li>
                        <li *ngIf="checkvar" class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link" routerLink="/on-boarding/TestPage" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <span class="menu-text">Test Page</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="menu-item menu-item-submenu" aria-haspopup="true" data-menu-toggle="hover" routerLinkActive="menu-item-open">
                <a class="menu-link menu-toggle" routerLinkActive="active">

                    <span class="svg-icon menu-icon">
                        <i class="fas fa-file" aria-hidden="true"></i></span>
                    <span class="menu-text" translate="Engineer"></span>
                    <i class="menu-arrow"></i>
                </a>
                <div class="menu-submenu">
                    <i class="menu-arrow"></i>
                    <ul class="menu-subnav">
                        <li class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link" routerLink="/engineer/EngineerClientList" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <!-- <span class="menu-text">Roles</span> -->
                                <span class="menu-text" translate="Advanced Services Client List"></span>
                            </a>
                        </li>
                        <li class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link" routerLink="/engineer/NDEproject" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <!-- <span class="menu-text">Roles</span> -->
                                <span class="menu-text" translate="NDE Project List"></span>
                            </a>
                        </li>
                        <li class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link" routerLink="/engineer/SkillMatrix" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <!-- <span class="menu-text">Roles</span> -->
                                <span class="menu-text" translate="Skills Matrix"></span>
                            </a>
                        </li>
                        <li class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link" routerLink="/engineer/EmployeesList" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <!-- <span class="menu-text">Roles</span> -->
                                <span class="menu-text" translate="Employees List"></span>
                            </a>
                        </li>
                        <li class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link"  routerLink="/engineer/ClientList" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <!-- <span class="menu-text">Roles</span> -->
                                <span class="menu-text" translate="Basic Services Client List"></span>
                            </a>
                        </li>
                        <li class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link"  routerLink="/engineer/InActiveClientList" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <!-- <span class="menu-text">Roles</span> -->
                                <span class="menu-text" translate="Inactive Client List"></span>
                            </a>
                        </li>
                        <li class="menu-item" routerLinkActive="menu-item-active" aria-haspopup="true" data-menu-toggle="hover">
                            <a class="menu-link"  routerLink="/engineer/ClientLogs" routerLinkActive="active">
                                <i class="menu-bullet menu-bullet-dot">
                                    <span></span>
                                </i>
                                <!-- <span class="menu-text">Roles</span> -->
                                <span class="menu-text" translate="Client Logs"></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
        <!-- end::Menu Nav -->
    </div>
    <!--end::Menu Container-->
</div>
<!--end::Aside Menu-->