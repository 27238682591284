import { Injectable, OnDestroy, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { TableService, TableResponseModel, ITableState, BaseModel, PaginatorState, SortState, GroupingState } from '../../../_metronic/shared/crud-table';
import { baseFilter } from '../../../_helpers/http-extenstions';
import { environment } from '../../../../environments/environment';
import { EncrDecrService } from '../../../_helpers/encr-decr.service';
import { Router } from '@angular/router';


const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

@Injectable({
  providedIn: 'root'
})

export class OnBoardingService extends TableService<any> implements OnDestroy {

  API_URL = `${environment.apiUrl}/v1/OnBoarding`;
  httpClient: any;
  constructor(@Inject(HttpClient) http, @Inject(EncrDecrService) EncrDecr) {
    super(http, EncrDecr);
  }

  getManagerslist(): Observable<any> {
    const url = `${this.API_URL}/GetManagersList`;
    return this.http.get(url).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    )
  }

  getClientlist(): Observable<any> {
    const url = `${this.API_URL}/GetOnBoardingClientList`;
    return this.http.get(url).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    )
  }

  getEngineerlist(): Observable<any> {
    const url = `${this.API_URL}/GetOnBoardingEngineersList`;
    return this.http.get(url).pipe(
      map((response) => { 
      let dec_data= this.EncrDecr.getobj(response['data'])
      response['data'] = dec_data
        return response;
      })
    )
  }

  getSolutionArclist(): Observable<any> {
    const url = `${this.API_URL}/GetSolutionsArchitecturesList`;
    return this.http.get(url).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    )
  }

  getSkillMat(): Observable<any> {
    const url = `${this.API_URL}/GetCategoryWiseSkills`;
    return this.http.get(url).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    )
  }

  SaveArchitecture(item: any): Observable<any> {
    let tostring = JSON.stringify(item)
    let enc_data = encodeURIComponent(this.EncrDecr.setobj(tostring))
    
    const url = `${this.API_URL}/SaveArchitecture?data=${enc_data}`;
    return this.http.post<BaseModel>(url, enc_data).pipe(
    );
  }
  SaveManager(item: any): Observable<any> {
    let tostring = JSON.stringify(item)
    let enc_data = encodeURIComponent(this.EncrDecr.setobj(tostring))
    
    const url = `${this.API_URL}/SaveManager?data=${enc_data}`;
    return this.http.post<BaseModel>(url, enc_data).pipe(
    );
  }
  
  SaveEngineer(item: any): Observable<any> {
    let tostring = JSON.stringify(item)
    let enc_data = encodeURIComponent(this.EncrDecr.setobj(tostring))
    
    const url = `${this.API_URL}/SaveEngineer?data=${enc_data}`;
    return this.http.post<BaseModel>(url, enc_data).pipe(
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

}