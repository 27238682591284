<ng-container>
  <ng-container >
    <!--begin::Header-->
    <div class="d-flex align-items-center p-8 rounded-top">
      <!--begin::Symbol-->
      <div class="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
        <!-- <img src="./assets/media/users/blank.png" alt="" /> -->
        <img [src]="imageToShow"
        alt="Place image title" onerror="this.onerror=null;this.src='./assets/images/user_placeholder_img.jpg';"
        *ngIf="!isImageLoading"/>
      </div>
      <div class="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
        {{userName}}
      </div>
      <!--end::Text-->
    </div>
    <!--end::Header-->
  </ng-container>
  
    <!--begin::Footer-->
    <div class="navi-separator mt-3"></div>
    <div class="navi-footer px-8 py-5">
      <a class="btn btn-light-primary font-weight-bold cursor-pointer" (click)="logOut()">Sign Out</a>
    </div>
    <!--end::Footer-->
  <!--end::Nav-->
</ng-container>