import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engineer',
  templateUrl: './engineer.component.html',
  styleUrls: ['./engineer.component.scss']
})
export class EngineerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
