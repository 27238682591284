import { ChangeDetectorRef,Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../../../../core';
import { OnBoardingService } from 'src/app/modules/on-boarding/_services/on-boarding.service';
import { SkillMatrixService } from 'src/app/modules/engineer/_services/skill-matrix.service';

@Component({
  selector: 'app-mixed-widget4',
  templateUrl: './mixed-widget4.component.html',
})
export class MixedWidget4Component implements OnInit {
  chartOptions: any = {};
  fontFamily = '';
  colorsGrayGray500 = '';
  colorsGrayGray200 = '';
  colorsGrayGray300 = '';
  colorsThemeBaseDanger = '';
  engList: any[] = [];
  categoryList: any[] =[];
  skillsList: any[] =[];
  categoryId : any = "2";
  @Input() cssClass;

  constructor(
    private layout: LayoutService,
    private onBoardingService : OnBoardingService,
    public SkillMatrixService: SkillMatrixService,
    private cdr: ChangeDetectorRef
    ) 
    {
    this.fontFamily = this.layout.getProp('js.fontFamily');
    this.colorsGrayGray500 = this.layout.getProp('js.colors.gray.gray500');
    this.colorsGrayGray200 = this.layout.getProp('js.colors.gray.gray200');
    this.colorsGrayGray300 = this.layout.getProp('js.colors.gray.gray300');
    this.colorsThemeBaseDanger = this.layout.getProp(
      'js.colors.theme.base.danger'
    );
  }

  ngOnInit(): void {
    this.chartOptions = this.getChartOptions();
    this.getEngList();
    this.getcategory();
    this.getSkillsByCategory(this.categoryId);
  }

  getEngList() {
    this.onBoardingService.getEngineerlist().subscribe(
      (res) => {
        this.engList = res['data'];
        this.cdr.detectChanges();
      },
    );
  }
  
  getcategory() {
    this.SkillMatrixService.getCateById().subscribe(res => {
      this.categoryList = res['data'];
      this.cdr.detectChanges();
    })
  }

  getSkillsByCategory(categoryId : any) {
    this.SkillMatrixService.getEngineerSkillsByCategoryId(categoryId).subscribe(res => {
      this.skillsList = res['data'];
      this.cdr.detectChanges();
    })
  }
  getChartOptions() {
    const strokeColor = '#D13647';
    return {
      series: [{
        name: 'Jan-2023',
        data: [1, 2, 2, 1, 3, 4, 4]
      },
      {
        name: 'Apr-2023',
        data: [1, 3, 2, 1, 4, 4, 6]
      },
      {
        name: 'Aug-2023',
        data: [2, 4, 4, 2, 4, 5, 6]
      }],
      chart: {
        type: 'bar',
        height: '200px',
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: true
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ['30%'],
          endingShape: 'rounded'
        },
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['C', 'DOTNET', 'HTML', 'VB', 'BASH', 'JS','PYTHON'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        labels: {
          style: {
            colors: this.colorsGrayGray500,
            fontSize: '12px',
            fontFamily: this.fontFamily
          }
        }
      },
      yaxis: {
        min: 0,
        max: 6,
        labels: {
          style: {
            colors: this.colorsGrayGray500,
            fontSize: '12px',
            fontFamily: this.fontFamily
          }
        }
      },
      fill: {
        type: ['solid', 'solid'],
        opacity: [0.25, 1]
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontFamily: this.fontFamily
        },
        y: {
          formatter: (val) => {
            return ` ${val} `;
          }
        },
        marker: {
          show: false
        }
      },
      colors: ['#ffffff', '#ffffff'],
      grid: {
        borderColor: this.colorsGrayGray200,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          left: 20,
          right: 20
        }
      }
    };
  }
}
