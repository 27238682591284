import { Injectable, OnDestroy, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { TableService, TableResponseModel, ITableState, BaseModel, PaginatorState, SortState, GroupingState } from '../../../_metronic/shared/crud-table';
import { baseFilter } from '../../../_helpers/http-extenstions';
import { environment } from '../../../../environments/environment';
import { EncrDecrService } from '../../../_helpers/encr-decr.service';
import { Router } from '@angular/router';


const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

@Injectable({
  providedIn: 'root'
})

export class SkillMatrixService extends TableService<any> implements OnDestroy {

  API_URL = `${environment.apiUrl}/v1/SkillsMatrix`;
  httpClient: any;
  constructor(@Inject(HttpClient) http, @Inject(EncrDecrService) EncrDecr) {
    super(http, EncrDecr);
  }

  getCateById(): Observable<any> {
    const url = `${this.API_URL}/GetCategories`;
    return this.http.get(url).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    )
  }

  getSkillsById(Catagories): Observable<any> {
    const url = `${this.API_URL}/GetAllSkillsMatrix`;
    return this.http.get(url, Catagories).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    );
  }
  getEngineerSkillsByCategoryId(CatagoryId: any): Observable<any> {

    let enc = encodeURIComponent(this.EncrDecr.setobj(CatagoryId.toString()));
    const url = `${this.API_URL}/GetEngineerSkillsByCategoryId?data=${enc}`;
    return this.http.get(url, CatagoryId).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    )
  }

  getSkillsByCategoryIds(categoryIds): Observable<any> {
    const url = `${this.API_URL}/GetSkillsMatrixByCategoryIds`;
    return this.http.post(url, categoryIds).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    );
  }

  getSkillsMatrixByEngIds(categoryIds): Observable<any> {
    const url = `${this.API_URL}/GetSkillsMatrixByEngIds`;
    return this.http.post(url, categoryIds).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    );
  }
  getAvgValues(AvgValue): Observable<any> {
    const url = `${this.API_URL}/GetEngineerSkillsWithAvgValueDefault`;
    return this.http.get(url, AvgValue).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    );
  }

  getAvgValue(AvgValue): Observable<any> {
    const url = `${this.API_URL}/GetEngineerSkillsWithAvgValue`;
    return this.http.post(url, AvgValue).pipe(
      map((response) => {
        let dec_data= this.EncrDecr.getobj(response['data'])
        response['data'] = dec_data
        return response;
      })
    );
  }

  updateSkillValue(item : any): Observable<any> {
    // const url = `${this.API_URL}/UpdateSkillsValue`;
    // return this.http.post<any>(url, data);

      let tostring = JSON.stringify(item)
      let enc_data = encodeURIComponent(this.EncrDecr.setobj(tostring))
      
      const url = `${this.API_URL}/UpdateSkillsValue?data=${enc_data}`;
      return this.http.post<BaseModel>(url, enc_data).pipe(
      );

  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

}