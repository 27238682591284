<div class="card card-custom bg-radial-gradient-danger" [ngClass]="cssClass">
  <!-- Header -->
  <div class="card-header border-0 py-5">
    <h3 class="card-title font-weight-bolder text-white">Skills Matrix</h3>
    <div class="card-toolbar">
      <div ngbDropdown placement="bottom-right" class="dropdown dropdown-inline" title="Quick actions">
        <a ngbDropdownToggle class="btn btn-text-white btn-hover-white btn-sm btn-icon border-0">
          <i class="ki ki-bold-more-hor"></i>
        </a>
        <!-- <div ngbDropdownMenu class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <ul class="navi navi-hover">
            <li class="navi-item" *ngFor="let item of engList">
              <a class="navi-link cursor-pointer">
                <span class="navi-icon"><i class="flaticon2-graph-1"></i></span>
                <span class="navi-text">{{item.Name}}</span>
              </a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="card-toolbar">
      <div ngbDropdown placement="bottom-right" class="dropdown dropdown-inline" title="Quick actions">
        <a ngbDropdownToggle class="btn btn-text-white btn-hover-white btn-sm btn-icon border-0">
          <i class="ki ki-bold-more-hor"></i>
        </a>
        <!-- <div ngbDropdownMenu class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <ul class="navi navi-hover">
            <li class="navi-item" *ngFor="let item of categoryList">
              <a class="navi-link cursor-pointer">
                <span class="navi-icon"><i class="flaticon2-graph-1"></i></span>
                <span class="navi-text">{{item.CategoryName}}</span>
              </a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
  <!-- Body -->
  <div class="card-body d-flex flex-column p-0">
    <!--begin::Chart-->
    <div id="kt_mixed_widget_4_chart" [style.height]="'200px'" [style.min-height]="'200px'">
      <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels" [stroke]="chartOptions.stroke"
        [legend]="chartOptions.legend" [fill]="chartOptions.fill" [states]="chartOptions.states"
        [tooltip]="chartOptions.tooltip" [colors]="chartOptions.colors" [markers]="chartOptions.markers"
        [plotOptions]="chartOptions.plotOptions"></apx-chart>
    </div>
    <!--end::Chart-->
    <!--begin::Stats-->
    <div class="card-spacer bg-white card-rounded flex-grow-1">
      <!--begin::Row-->
      <div class="row m-0">
        <div class="col px-8 py-6 mr-8">
          <div class="font-size-sm text-muted font-weight-bold">Engineer</div>
          <mat-select class="form-control form-control-solid" placeholder="Select Engineer" style="margin-top: 15px;">
            <mat-option *ngFor="let item of engList" [value]="item.Id">{{item.Name}}</mat-option>
          </mat-select>
        </div>
        <div class="col px-8 py-6">
          <div class="font-size-sm text-muted font-weight-bold">Category</div>
          <mat-select class="form-control form-control-solid" placeholder="Select Catagory" style="margin-top: 15px;">
            <mat-option *ngFor="let item of categoryList" [value]="item.Id"
              (click)="getSkillsByCategory(item.Id)">{{item.CategoryName}}</mat-option>
          </mat-select>
        </div>
      </div>
      <!--end::Row-->
      <!--begin::Row-->
      <!--end::Row-->
    </div>
    <!--end::Stats-->
  </div>
</div>