import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxspinnercustomService } from './ngxspinnercustom.services';

@Component({
  selector: 'app-ngx-spinner',
  templateUrl: './ngx-spinner.component.html',
  styleUrls: ['./ngx-spinner.component.scss']
})
export class NgxSpinnerComponent implements OnInit {

  @Input() show: boolean;

  constructor(private spinner: NgxSpinnerService, private spinnerservice: NgxspinnercustomService) { }

  ngOnInit(): void {
    this.spinnerservice.getData().subscribe(data => {
      if (data) {
          this.spinner.show();
      } else {
          this.spinner.hide();
      }
  });
  }

}
