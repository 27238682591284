<!-- begin::Header Nav -->
<ul class="menu-nav" [ngClass]="ulCSSClasses">
    <!-- begin::1 Level -->
    <!-- <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
        <a (click)="sasLogin()" class="menu-link" routerLinkActive="active">
            <span class="menu-text">Auto SAS Login</span>
            <ng-container *ngIf="rootArrowEnabled">
                <i class="menu-arrow"></i>
            </ng-container>
        </a>
    </li> -->

    <!-- <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
        <a (click)="sasLogin()" class="menu-link" routerLinkActive="active">
            <span class="menu-text">SAS Login</span>
            <ng-container *ngIf="rootArrowEnabled">
                <i class="menu-arrow"></i>
            </ng-container>
        </a>
    </li> -->

    <!-- <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
        <a (click)="saspostLogin()" class="menu-link" routerLinkActive="active">
            <span class="menu-text">SAS Post Login</span>
            <ng-container *ngIf="rootArrowEnabled">
                <i class="menu-arrow"></i>
            </ng-container>
        </a>
    </li> -->
    <!-- end::1 Level -->
</ul>
<!-- end::Header Nav -->
<!-- <ngx-spinner bdColor="rgba(0,0,0, 0.5)" size="medium" color="#fff" type="ball-triangle-path">
    <p style="color: white"> Loading... </p>
</ngx-spinner> -->