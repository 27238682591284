import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseModel } from '../_metronic/shared/crud-table';

@Injectable({
  providedIn: 'root'
})

export class EventService {
  private fooSubject = new Subject<any>();

  constructor(private http: HttpClient) {}
  
  publishSomeData(data: any) {
      this.fooSubject.next(data);
  }

  getObservable(): Subject<any> {
      return this.fooSubject;
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.http
      .get(imageUrl, {
        responseType: 'blob',
        headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  
}
