import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import { Subscription } from 'rxjs';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  role_id: number;
  login : boolean = false;
  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private msalService: MsalService,
    private http: HttpClient,
    private cd: ChangeDetectorRef
    
  ) {
  }

  ngOnInit() {
    //this.test()
    this.msalService.instance.handleRedirectPromise().then( res => {
      if (res != null && res.account != null) {
        this.msalService.instance.setActiveAccount(res.account)
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		    // this.router.onSameUrlNavigation = 'reload';
      }
      else{
        this.msalService.loginRedirect()
      }
    })
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.tableService.setDefaults();
        this.splashScreenService.hide();
        window.scrollTo(0, 0);
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }


  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null
  }

  // async test ()
  // {
  //   const response = await this.msalService.instance.getActiveAccount()
  //   console.log(sessionStorage.getItem('secret'))
  //   if(response !== null)
  //   {
  //     this.login = true;
  //   } else {
  //     try {
  //       const userAccount = await this.msalService.instance.handleRedirectPromise();
  //       this.msalService.instance.setActiveAccount(userAccount.account);
  //       const request = await this.msalService.acquireTokenSilent({
  //         scopes: [
  //           'https://graph.microsoft.com/v1.0/me/read'
  //         ],
  //         authority: "https://login.microsoftonline.com/906aefe9-76a7-4f65-b82d-5ec20775d5aa",
  //         account: this.msalService.instance.getActiveAccount()
  //       }).toPromise();
  //       console.log("Sign in------------------------------->", request)
  //       this.cd.detectChanges();

  //     }
  //     catch(e) {
  //       console.log("Error while sign",e)
  //     }
  //   }
  // }
 
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
