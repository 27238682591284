import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LayoutService } from "../../../../_metronic/core";
import { MsalService } from '@azure/msal-angular';
@Component({
  selector: "app-aside",
  templateUrl: "./aside.component.html",
  styleUrls: ["./aside.component.scss"],
})
export class AsideComponent implements OnInit {
  checkvar : boolean = false;
  disableAsideSelfDisplay: boolean;
  headerLogo: string;
  brandSkin: string;
  ulCSSClasses: string;
  location: Location;
  asideMenuHTMLAttributes: any = {};
  asideMenuCSSClasses: string;
  asideMenuDropdown;
  brandClasses: string;
  asideMenuScroll = 1;
  asideSelfMinimizeToggle = false;

  isUser: any = 0;
  isRoles: any = 0;
  isPermission: any = 0;
  isIssueTracking: any = 0;
  isDynamicForm: any = 0;
  isChart: any = 0;
  isAuditManagement: any = 0;
  role_id: any;
  isDynamicFormgenerate: any = 0;
  isDynamicFormcollection: any = 0;

  constructor(
    private layout: LayoutService,
    private loc: Location,
    private msalService: MsalService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // load view settings
    this.disableAsideSelfDisplay =
      this.layout.getProp("aside.self.display") === false;
    this.brandSkin = this.layout.getProp("brand.self.theme");
    this.headerLogo = this.getLogo();
    this.ulCSSClasses = this.layout.getProp("aside_menu_nav");
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses("aside_menu");
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes("aside_menu");
    this.asideMenuDropdown = this.layout.getProp("aside.menu.dropdown")
      ? "1"
      : "0";
    this.brandClasses = this.layout.getProp("brand");
    this.asideSelfMinimizeToggle = this.layout.getProp(
      "aside.self.minimize.toggle"
    );
    this.asideMenuScroll = this.layout.getProp("aside.menu.scroll") ? 1 : 0;
    // this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''}`;
    // Routing

    this.location = this.loc;
   
  }

  private getLogo() {
    if (this.brandSkin === "light") {
      return "./assets/media/logos/logo-dark.png";
    } else {
      return "./assets/media/logos/nde_logo.svg.png";
    }
  }

  CheckUserRole() {
    let allAccounts = this.msalService.instance.getAllAccounts();
    if (allAccounts.length > 0) {
      let account = allAccounts[0];
      let role = account.idTokenClaims['roles'][0];
      if(role = 'a193fc57-049c-4f50-9ea5-eeaf9f61bd05_user'){
        this.checkvar = true;
      }
    }
    this.cdr.detectChanges();  
  }
}
