import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { EventService } from 'src/app/_services/event.service';
@Component({
  selector: 'app-dropdown-menu1',
  templateUrl: './dropdown-menu1.component.html',
})
export class DropdownMenu1Component implements OnInit {
  userName: any;
  firstName : any;
  lastName : any;
  displayName : any;
  apiResponse: string;
  isImageLoading: boolean = false;
  imageToShow: any;
  constructor(
    private router: Router,
    private msalService : MsalService,
    private http: HttpClient,
    private _eventService : EventService,
    private domSanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    this.callProfile()
    this.getProfilePicture()
  }

  logOut() {
    localStorage.removeItem('login');
    this.msalService.logout();
  }

  async callProfile () {
    await this.http.get("https://graph.microsoft.com/v1.0/me").subscribe( resp  => {
      this.firstName = resp['givenName'];
      this.lastName = resp['surname'];
      this.userName = this.firstName + " " + this.lastName;
      //this.userName = resp['displayName'];
      this.displayName = resp['givenName'];
    })
  }

  getProfilePicture() {
    this.isImageLoading = true;
    this._eventService.getImage('https://graph.microsoft.com/v1.0/me/photo/$value').subscribe(
      (blob) => {
        this.isImageLoading = false;

        var urlCreator = window.URL || window.webkitURL;
        this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(
          urlCreator.createObjectURL(blob)
        );
      },
      (error) => {
        this.isImageLoading = false;
        console.log(error);
      }
    );
  }
  
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        console.log(reader.result);
        const imgRes: any = reader.result;
        this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(imgRes);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
