import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TableService } from './table.service';
import {EncrDecrService} from '../../../../_helpers/encr-decr.service';

@Injectable({
  providedIn: 'root'
})
export class TableExtendedService extends TableService<any> {
  constructor(http: HttpClient, EncrDecr: EncrDecrService) {
    super(http, EncrDecr);
  }
}
