<!--begin::Subheader-->
<div class="subheader py-2 py-lg-6" [ngClass]="subheaderCSSClasses" id="kt_subheader">
  <div [ngClass]="subheaderContainerCSSClasses"
    class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex align-items-center flex-wrap mr-1">
      <ng-container *ngIf="subheaderMobileToggle">
        <!--begin::Mobile Toggle-->
        <button class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none" id="kt_subheader_mobile_toggle">
          <span></span>
        </button>
        <!--end::Mobile Toggle-->
      </ng-container>

      <!--begin::Page Heading-->
      <div class="d-flex align-items-baseline flex-wrap mr-5">
        <!--begin::Page Title-->
        <ng-container *ngIf="title$ | async as _title">
          <h5 class="text-dark font-weight-bold my-1 mr-3">
            {{ _title }}
            <ng-container *ngIf="subheaderDisplayDesc">
              <ng-container *ngIf="description$ | async as _description">
                <small>{{ _description }}</small>
              </ng-container>
            </ng-container>
          </h5>
        </ng-container>
        <!--end::Page Title-->

        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
          <li class="breadcrumb-item" *ngFor="let bc of breadcrumbs" routerLinkActive="active">
            <a [routerLink]="bc.linkPath" class="text-muted">
              {{ bc.linkText }}
            </a>
          </li>
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page Heading-->
    </div>
    <!--end::Info-->

    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <ng-container *ngIf="subheaderDisplayDaterangepicker">
        <!--begin::Daterange-->
        <a class="btn btn-light-primary btn-sm font-weight-bold mr-2 cursor-pointer" id="kt_dashboard_daterangepicker"
          data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
          <span class="opacity-60 font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
          <span class="font-weight-bold" id="kt_dashboard_daterangepicker_date">{{currentDate | date:'MMM dd'}}</span>
        </a>
        <!--end::Daterange-->
      </ng-container>

      <ng-container>
        <!--begin::User-->
        <ng-container>
          <div class="dropdown" ngbDropdown placement="bottom-right">
            <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
              <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
                <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{userName}}</span>
                <span class="symbol symbol-35 symbol-light-success">
                  <!-- <span class="symbol-label font-size-h5 font-weight-bold">{{firstCharacter}}</span> -->
                    <img [src]="imageToShow"
                    alt="Place image title" onerror="this.onerror=null;this.src='./assets/images/user_placeholder_img.jpg';"
                    *ngIf="!isImageLoading"/>
                </span>
              </div>
            </div>
            <!--end::Toggle-->
            <!--begin::Dropdown-->
            <div class="dropdown-menu dropdown-menu-md dropdown-menu-right p-0 m-0" ngbDropdownMenu>
              <app-dropdown-menu1></app-dropdown-menu1>
            </div>
          </div>
          <!--end::Dropdown-->
        </ng-container>
        <!--end::User-->
      </ng-container>
      <ng-container *ngIf="!subheaderDisplayDaterangepicker">
        <!--begin::Actions-->
        <a class="btn btn-light-primary font-weight-bolder btn-sm cursor-pointer">
          Actions
        </a>
        <!--end::Actions-->
      </ng-container>

          </div>
    <!--end::Toolbar-->
  </div>
</div>
<!--end::Subheader-->